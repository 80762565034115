import LazyLoad from "vanilla-lazyload";

const ready = (fn) => {
  if (document.readyState != 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  sizesInit();
  lazyInit();
  videoInit();
  videosInit();
});

const is_touch_device = () => {
  return window.matchMedia('(hover: none)').matches;

    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  
  const mq = (query) => {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
      return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

const lazyInit = () => {
  const lazyLoader = new LazyLoad({
    elements_selector: '.lazy',
  });
};

const videoInit = () => {
  const control = document.querySelectorAll('.video-wrapper .play-overlay')[0];
  const video = document.querySelectorAll('.video-wrapper video')[0];

  video.onpause = (event) => {
    video.removeAttribute('controls', '');
    control.style.display = '';
  };

  control.addEventListener('click', (event) => {
    video.play();
    video.setAttribute('controls', 'controls');
    control.style.display = 'none';
  });
};

const sizesInit = () => {
  sizesUpdate();

  window.addEventListener('resize', sizesUpdate);
};

const sizesUpdate = () => {
  document.querySelectorAll('.video-entry').forEach((videoContainer) => {
    const width = videoContainer.offsetWidth;
    const height = width * .56 + 2;

    const video = videoContainer.querySelector('video');
    
    videoContainer.style.height = height + 'px';
  });
};

const videosInit = () => {
  const isTouch = is_touch_device();

  document.querySelectorAll('.video-entry').forEach((videoContainer) => {
    const video = videoContainer.querySelector('video');
    const control = videoContainer.querySelector('.play-overlay');

    if (isTouch) {
      control.style.display = 'block';
    }

    video.removeAttribute('controls');

    video.onpause = (event) => {
      video.removeAttribute('controls', '');

      if (isTouch) {
        control.style.display = 'block';
      }
    };
  
    control.addEventListener('click', (event) => {
      video.play();
      video.setAttribute('controls', 'controls');
      control.style.display = 'none';
    });

    video.addEventListener('mouseover', (event) => {
      const media = event.target;

      media.play();
    }, false);

    video.addEventListener('mouseout', (event) => {
      const media = event.target;

      media.pause();
    }, false);
  });
};